import axios from "axios";
import bbox from "@turf/bbox";
import points_within_polygon from "@turf/points-within-polygon";

import {
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
} from "./authActions";
import manifest_demography from "../../Data/manifest_demography.json";
import manifest_people_spending from "../../Data/manifest_people_spending.json";
import aggregate_property_price from "../validation/aggregate_property_price";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

export const set_value_sini = (body) => (dispatch) => {
  dispatch({
    type: "set_value_sini",
    payload: body,
  });
  dispatch({
    type: "state_update",
  });
};

export const pull_value_sini_object = (body) => (dispatch) => {
  dispatch({
    type: "pull_value_sini_object",
    payload: body,
  });
  dispatch({
    type: "state_update",
  });
};

export const state_update = () => {
  return {
    type: "state_update",
  };
};

export const fly_init_update = () => {
  return {
    type: "fly_init_update",
  };
};

export const fly_init_marker = () => {
  return {
    type: "fly_init_marker",
  };
};

/**
 * @route : delete_ai_item
 * @endpoint : /sini_v2/delete_ai_item
 * @methode : delete
 * @query : -
 */
export const delete_ai_item = (query) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "delete_ai_item",
    });

    const { request_id } = query;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      bun_server + `/sini_v2/delete_ai_item?request_id=${request_id}`,
      config
    );
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_list",
        value: request_id,
        id: "request_id",
      },
    });
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_detail_list",
        value: request_id,
        id: "request_id",
      },
    });
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_child_list",
        value: request_id,
        id: "request_id",
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_active",
        value: "",
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_child_list",
        value: [],
      },
    });

    dispatch({
      type: "clear_loading_action",
      payload: "delete_ai_item",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "delete_ai_item",
    });
  }
};

/**
 * @route : get_ai_parent_list
 * @endpoint : /sini_v2/get_ai_parent_list
 * @methode : get
 * @params : -
 */
export const get_ai_parent_list = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_parent_list",
    });

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_list`,
      config
    );
    const data = res.data || [];
    dispatch(
      set_value_sini({
        key: "ai_parent_list",
        value: data,
      })
    );

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_list",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_list",
    });
  }
};

/**
 * @route : get_ai_parent_object
 * @endpoint : /sini_v2/get_ai_parent_object
 * @methode : get
 * @params : request_id
 */
export const get_ai_parent_object = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_parent_object",
    });

    const { request_id } = params;
    dispatch(
      set_value_sini({
        key: "request_id_active",
        value: request_id,
      })
    );
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_object?request_id=${request_id}`,
      config
    );
    const data = res.data;
    if (data) {
      dispatch({
        type: "edit_inside_array_sini",
        payload: {
          key: "ai_parent_detail_list",
          id_key_parent: "request_id",
          id_value_parent: request_id,
          value_child: data,
        },
      });
    }
    dispatch(fly_init_update());
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_loading",
        value: "",
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "sini_marker_active",
        value: {},
      },
    });
    dispatch({
      type: "state_update",
    });

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_object",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_object",
    });
  }
};

/**
 * @route : get_ai_parent_status
 * @endpoint : /sini_v2/get_ai_parent_status
 * @methode : get
 * @params : request_id
 */
export const get_ai_parent_status = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_child_list",
    });

    const { request_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_status?request_id=${request_id}`,
      config
    );
    const data = res.data;
    if (data) {
      dispatch({
        type: "edit_inside_array_sini",
        payload: {
          key: "ai_parent_detail_list",
          id_key_parent: "request_id",
          id_value_parent: request_id,
          value_child: data,
        },
      });
    }
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_status",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_status",
    });
  }
};

/**
 * @route : get_ai_child_list
 * @endpoint : /sini_v2/get_ai_child_list
 * @methode : get
 * @params : request_id
 */
export const get_ai_child_list = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_child_list",
    });

    const { request_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_child_list?request_id=${request_id}`,
      config
    );
    const data = res.data;

    //masukan detail di ai_parent_detail_list, termasuk ai_child_list, nantinya ai_child_list tidak dipakai, logic ketika sudah ada dan tidak ada dihandle difungsi di bawah
    const object = {
      request_id,
      ai_child_list: data,
    };
    dispatch({
      type: "edit_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        value_child: object,
      },
    });

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_child_list",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_child_list",
    });
  }
};

/**
 * @route : post_ai_chat
 * @endpoint : /sini_v2/post_ai_chat
 * @methode : post
 * @body : request_id, user_message, json_ai
 */
export const post_ai_chat = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "post_ai_chat",
    });

    const { request_id, user_message, sini_object } = body;
    const { title_1, title_2 } = sini_object;
    const title = title_1 + " " + title_2;
    //create new AI_CHILD role: "user"
    const object_user_chat = {
      user: "",
      request_id,
      role: "user",
      content: user_message,
      date_created: Date.now(),
      date_modified: Date.now(),
    };
    dispatch({
      type: "push_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "ai_child_list",
        value_array: [object_user_chat],
        value_object: { request_id, ai_child_list: [object_user_chat] },
      },
    });

    //step 1 generate json_ai yang berisi propertiesnya saja
    let json_ai = {};

    /*OBJECT*/

    //demography_one
    if (sini_object?.demography_one?._id) {
      json_ai.demography_one = {
        properties: sini_object?.demography_one?.properties || {},
      };
    }

    //people_spending
    if (sini_object?.people_spending?._id) {
      json_ai.people_spending = {
        properties: sini_object?.people_spending?.properties || {},
      };
    }

    //landzone_one
    if (sini_object?.landzone_one?._id) {
      json_ai.landzone_one = {
        properties: sini_object?.landzone_one?.properties || {},
      };
    }

    //flood_one
    if (sini_object?.flood_one?._id) {
      json_ai.flood_one = {
        properties: sini_object?.flood_one || {},
      };
    } else {
      json_ai.flood_one = {
        properties: {
          status: "Tidak ada data kerawanan banjir",
        },
      };
    }

    //landslide_one
    if (sini_object?.landslide_one?._id) {
      json_ai.landslide_one = {
        properties: sini_object?.landslide_one?.properties || {},
      };
    } else {
      json_ai.landslide_one = {
        properties: {
          status: "Tidak ada data kerawanan longsor",
        },
      };
    }

    //tsunami_one
    if (sini_object?.tsunami_one?._id) {
      json_ai.tsunami_one = {
        properties: sini_object?.tsunami_one?.properties,
      };
    } else {
      json_ai.tsunami_one = {
        properties: {
          status: "Tidak ada data kerawanan tsunami",
        },
      };
    }

    //rdtr_one
    if (sini_object?.rdtr_one?._id) {
      json_ai.rdtr_one = {
        properties: {
          NAMZON_1: sini_object?.rdtr_one?.properties?.["NAMZON_1"],
        },
      };
    }

    /*ARRAY*/

    //demography_neighbor

    //landzone_neighbor
    let landzone_neighbor_ai = [];
    if (sini_object?.landzone_neighbor?.length > 0) {
      landzone_neighbor_ai = sini_object.landzone_neighbor.map((item) => {
        return {
          properties: item?.properties || {},
        };
      });
    }
    json_ai.landzone_neighbor = landzone_neighbor_ai;

    //flood_neighbor
    let flood_neighbor_ai = [];
    if (sini_object?.flood_neighbor?.length > 0) {
      flood_neighbor_ai = sini_object.flood_neighbor.map((item) => {
        return {
          properties: item?.properties || {},
        };
      });
    }
    json_ai.flood_neighbor = flood_neighbor_ai;

    //landslide_neighbor
    let landslide_neighbor_ai = [];
    if (sini_object?.landslide_neighbor?.length > 0) {
      landslide_neighbor_ai = sini_object.landslide_neighbor.map((item) => {
        return {
          properties: item?.properties || {},
        };
      });
    }
    json_ai.landslide_neighbor = landslide_neighbor_ai;

    //tsunami_neighbor
    let tsunami_neighbor_ai = [];
    if (sini_object?.tsunami_neighbor?.length > 0) {
      tsunami_neighbor_ai = sini_object.tsunami_neighbor.map((item) => {
        return {
          properties: item?.properties || {},
        };
      });
    }
    json_ai.tsunami_neighbor = tsunami_neighbor_ai;

    //rdtr_neighbor

    /*POI*/

    //poi_features
    let poi_features_ai = [];
    if (sini_object?.poi_features?.length > 0) {
      poi_features_ai = []; //Untuk sekarang untuk menghindari limitasi jumlah token/kata API AI, titik tidak dikirim
    }
    json_ai.poi_features = poi_features_ai;

    //poi_resume_1
    let poi_resume_1 = [];
    if (sini_object?.poi_resume_1?.length > 0) {
      poi_resume_1 = sini_object?.poi_resume_1;
    }
    json_ai.poi_resume_1 = poi_resume_1;

    //poi_resume_2
    let poi_resume_2 = [];
    if (sini_object?.poi_resume_2?.length > 0) {
      poi_resume_2 = sini_object?.poi_resume_2;
    }
    json_ai.poi_resume_2 = poi_resume_2;

    //poi_resume_3
    let poi_resume_3 = [];
    if (sini_object?.poi_resume_3?.length > 0) {
      poi_resume_3 = sini_object?.poi_resume_3;
    }
    json_ai.poi_resume_3 = poi_resume_3;

    /*HARGA TANAH*/

    if (sini_object?.poi_survey_1_features?.length > 0) {
      /*
      poi_survey_1_features,
      jenis_bangunan_resume,
      average_sewa,
      min_sewa,
      max_sewa,
      count_sewa,
      manifest_survey_1,
      */
      const {
        poi_survey_1_features,
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
        manifest_survey_1,
      } = sini_object;
      const object_loop = {
        poi_survey_1_features,
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
        manifest_survey_1,
      };
      json_ai = { ...json_ai, ...object_loop };
    } else {
      //landvalue_one
      if (sini_object?.landvalue_one?._id) {
        json_ai.landvalue_one = {
          properties: sini_object?.landvalue_one?.properties || {},
        };
      }

      //landvalue_neighbor
      let landvalue_neighbor_ai = [];
      if (sini_object?.landvalue_neighbor?.length > 0) {
        landvalue_neighbor_ai = sini_object?.landvalue_neighbor.map((item) => {
          return { properties: item?.properties || {} };
        });
      }
      json_ai.landvalue_neighbor = landvalue_neighbor_ai;
    }

    //akses API post_ai_chat
    const body_post_ai_chat = {
      request_id: request_id,
      user_message,
      json_ai,
      title,
      manifest_demography,
      manifest_people_spending,
    };
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      bun_server + "/sini_v2/post_ai_chat",
      body_post_ai_chat,
      config
    );
    const response = res.data;
    const { object_assistant_chat } = response;
    dispatch({
      type: "push_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "ai_child_list",
        value_array: [object_assistant_chat],
        value_object: {
          request_id,
          ai_child_list: [object_user_chat, object_assistant_chat],
        },
      },
    });
    dispatch({
      type: "state_update",
    });
    dispatch({
      type: "clear_loading_action",
      payload: "post_ai_chat",
    });
    dispatch(get_quota_ai_chat());
    dispatch(get_ai_parent_status({ request_id }));
  } catch (error) {
    const data = error?.response?.data || {};
    const is_error_limitation = data?.is_error_limitation;
    const is_error_bill_ai = data?.is_error_bill_ai;
    if (is_error_limitation) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "modal_error_limitation",
          value: true,
        },
      });
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "error_limitation",
          value: data,
        },
      });
    } else if (is_error_bill_ai) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "modal_error_bill_ai",
          value: true,
        },
      });
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "error_limitation",
          value: data,
        },
      });
    }
    dispatch({
      type: "clear_loading_action",
      payload: "post_ai_chat",
    });
  }
};

//get_sini_data
export const get_sini_data = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "sini_marker_active",
        value: params,
      },
    });
    dispatch({
      type: "set_loading_action",
      payload: "sini_load_parent",
    });

    const { lat, long, request_id, sini_data_mode, show_survey_1 } = params;
    let { km_rad } = params;

    let action_array = [];
    let geojson_polygon_isochrone = {};

    if (sini_data_mode === "sini_general") {
      action_array = [
        "demography_one",
        "people_spending",
        "landvalue",
        "landzone",
        "poi_property",
        "flood",
        "landslide",
        "tsunami",
        "demography_neighbor",
        "poi",
      ];
      if (show_survey_1) action_array.push("poi_survey_1_features");
    } else {
      action_array = ["demography_one", "rdtr"];
    }

    km_rad = km_rad || 1;
    let title_1 = "Untitled";
    let title_2 = "Untitled";

    const sini_init = {
      request_id,
      lat,
      long,
      km_rad,
    };
    //to do: langsung bisa pakai ai_parent_list, ai_parent_detail_list, ai_parent_child_list
    //kemungkinan sini_v2_list sudah tidak dipakai lagi

    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_list",
        value: [sini_init],
      },
    });
    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_detail_list",
        value: [sini_init],
      },
    });
    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_child_list",
        value: [sini_init],
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_active",
        value: request_id,
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_loading",
        value: request_id,
      },
    });

    let json_db = {};

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    //untuk kasus POI ambil isokron terlebih dahulu untuk query ke DB,
    //karena POI query polygonnya menggunakan isokron, bukan radius

    const isochrone_mode = "driving"; //Isochrone API Profiles: driving, driving-traffic, walking, cycling
    const contours_minutes = 10;
    const contours_meters = 10;
    const contours_type = "minutes";
    let isochrone_url;
    if (contours_type === "minutes") {
      isochrone_url = `https://api.mapbox.com/isochrone/v1/mapbox/${isochrone_mode}/${long}%2C${lat}?contours_minutes=${contours_minutes}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    } else {
      isochrone_url = `https://api.mapbox.com/isochrone/v1/mapbox/${isochrone_mode}/${long}%2C${lat}?contours_meters=${contours_meters}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    }
    const res_isokron = await axios.get(isochrone_url);
    geojson_polygon_isochrone = res_isokron?.data || {
      type: "FeatureCollection",
      features: [],
    };

    //geojson_polygon_isochrone
    dispatch({
      type: "edit_inside_array_sini",
      payload: {
        key: "ai_parent_detail_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "geojson_polygon_isochrone",
        value_child: geojson_polygon_isochrone,
      },
    });

    if (res_isokron?.data?.features?.[0]?.properties) {
      geojson_polygon_isochrone.features[0].properties = {
        ...geojson_polygon_isochrone.features[0].properties,
        isochrone_mode,
        contours_minutes,
        contours_meters,
        contours_type,
      };
    }

    json_db.geojson_polygon_isochrone = geojson_polygon_isochrone;

    const [min_long, min_lat, max_long, max_lat] = bbox(
      geojson_polygon_isochrone
    );

    let demography_one_id = "";
    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));
    const parent_function = () => {
      return action_array.reduce(
        (last_promise, object, idx) =>
          last_promise.then((result_sum) =>
            child_function(object, idx).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (item, idx) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            dispatch({
              type: "set_loading_action",
              payload: item,
            });

            if (item === "poi") {
              let res, data;
              let poi_features = [];
              let poi_resume_1 = [];
              let poi_resume_2 = [];
              let poi_resume_3 = [];
              /*
              poi:{
              poi_resume_1 : [],
              poi_resume_2 : [],
              poi_features: []
              }
              */
              res = await axios.get(
                bun_server +
                  `/sini_v2/${item}?lat=${lat}&long=${long}&min_long=${min_long}&min_lat=${min_lat}&max_long=${max_long}&max_lat=${max_lat}`,
                config
              );
              data = res?.data || [];
              const geojson_point = {
                type: "FeatureCollection",
                features: data,
              };

              const geojson_filtered = await points_within_polygon(
                geojson_point,
                geojson_polygon_isochrone
              );

              data = geojson_filtered?.features || [];
              data.forEach((feature) => {
                //tipe 1
                const name_tipe_1 = feature?.properties?.["TIPE_1"];
                let number_tipe_1 =
                  poi_resume_1.find((item) => name_tipe_1 === item.name)
                    ?.number || 0;
                number_tipe_1++;
                let index_tipe_1 = poi_resume_1.findIndex(
                  (item) => name_tipe_1 === item.name
                );
                if (index_tipe_1 > -1) {
                  poi_resume_1[index_tipe_1].number = number_tipe_1;
                } else {
                  poi_resume_1.push({
                    name: name_tipe_1,
                    number: number_tipe_1,
                  });
                }
                //tipe 2
                const name_tipe_2 = feature?.properties?.["TIPE_2"];
                let number_tipe_2 =
                  poi_resume_2.find((item) => name_tipe_2 === item.name)
                    ?.number || 0;
                number_tipe_2++;
                let index_tipe_2 = poi_resume_2.findIndex(
                  (item) => name_tipe_2 === item.name
                );
                if (index_tipe_2 > -1) {
                  poi_resume_2[index_tipe_2].number = number_tipe_2;
                } else {
                  poi_resume_2.push({
                    name: name_tipe_2,
                    parent: name_tipe_1,
                    number: number_tipe_2,
                  });
                }
                //tipe 3
                const name_tipe_3 = feature?.properties?.["TIPE_3"];
                let number_tipe_3 =
                  poi_resume_3.find((item) => name_tipe_3 === item.name)
                    ?.number || 0;
                number_tipe_3++;
                let index_tipe_3 = poi_resume_3.findIndex(
                  (item) => name_tipe_3 === item.name
                );
                if (index_tipe_3 > -1) {
                  poi_resume_3[index_tipe_3].number = number_tipe_3;
                } else {
                  poi_resume_3.push({
                    name: name_tipe_3,
                    parent_tipe_1: name_tipe_1,
                    parent_tipe_2: name_tipe_2,
                    number: number_tipe_3,
                  });
                }
              });
              poi_features = [...poi_features, ...data];
              dispatch({
                type: "push_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_features",
                  value_array: data,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_1",
                  value_child: poi_resume_1,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_2",
                  value_child: poi_resume_2,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_3",
                  value_child: poi_resume_3,
                },
              });

              dispatch({
                type: "state_update",
              });
              /*POI json_db*/
              const poi_features_id = poi_features.map((item) => item._id);
              json_db.poi_features = poi_features_id;
              json_db.poi_resume_1 = poi_resume_1;
              json_db.poi_resume_2 = poi_resume_2;
              json_db.poi_resume_3 = poi_resume_3;
            } else if (item === "poi_survey_1_features") {
              //get manifest layer
              const res_manifest_survey_1 = await axios.get(
                bun_server + "/sini_v2/get_manifest_survey_1",
                config
              );
              const manifest_survey_1 = res_manifest_survey_1.data;

              const res = await axios.get(
                bun_server +
                  `/sini_v2/${item}?lat=${lat}&long=${long}&min_long=${min_long}&min_lat=${min_lat}&max_long=${max_long}&max_lat=${max_lat}`,
                config
              );
              let data = res?.data || [];
              const geojson_point = {
                type: "FeatureCollection",
                features: data,
              };
              const geojson_filtered = await points_within_polygon(
                geojson_point,
                geojson_polygon_isochrone
              );
              data = geojson_filtered?.features || [];
              let poi_survey_1_features = [];
              let jenis_bangunan_resume = []; //array of object, {name, number}
              let average_sewa = 0;
              let min_sewa = Infinity; //set initial value to a very high number
              let max_sewa = -Infinity; //set initial value to a very low number
              let total_sewa = 0;
              let count_sewa = 0;

              if (data.length > 0) {
                /*
                "key": "b8f5ae86-e29d-4970-883c-f743b5be39fb",
                "name": "Karakteristik Ekonomi: Harga Sewa/Tahun",
                
                "key": "3c76848c-ec41-411e-8fd1-b457dcae5593",
                "name": "Jenis Bangunan",

                poi_survey_1_features,
                jenis_bangunan_resume: Array,
                average_sewa: Number,
                min_sewa: Number,
                max_sewa: Number,
                count_sewa: Number,
                */
                data.forEach((feature) => {
                  poi_survey_1_features.push(feature._id);
                  const name_tipe_1 =
                    feature?.properties?.[
                      "3c76848c-ec41-411e-8fd1-b457dcae5593"
                    ];
                  let number_tipe_1 =
                    jenis_bangunan_resume.find(
                      (item) => name_tipe_1 === item.name
                    )?.number || 0;
                  number_tipe_1++;
                  let index_tipe_1 = jenis_bangunan_resume.findIndex(
                    (item) => name_tipe_1 === item.name
                  );
                  if (index_tipe_1 > -1) {
                    jenis_bangunan_resume[index_tipe_1].number = number_tipe_1;
                  } else {
                    jenis_bangunan_resume.push({
                      name: name_tipe_1,
                      number: number_tipe_1,
                    });
                  }
                  const value_sewa = parseFloat(
                    feature?.properties?.[
                      "b8f5ae86-e29d-4970-883c-f743b5be39fb"
                    ]
                  );
                  if (!isNaN(value_sewa)) {
                    total_sewa += value_sewa;
                    count_sewa++;
                    if (value_sewa < min_sewa) min_sewa = value_sewa;
                    if (value_sewa > max_sewa) max_sewa = value_sewa;
                  }
                });
                if (count_sewa > 0)
                  average_sewa = parseInt(total_sewa / count_sewa);
              } else {
                min_sewa = 0;
                max_sewa = 0;
              }
              json_db.poi_survey_1_features = poi_survey_1_features;
              json_db.jenis_bangunan_resume = jenis_bangunan_resume;
              json_db.average_sewa = average_sewa;
              json_db.min_sewa = min_sewa;
              json_db.max_sewa = max_sewa;
              json_db.total_sewa = total_sewa;
              json_db.count_sewa = count_sewa;
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_survey_1_features",
                  value_child: data,
                },
              });
              const object_survey_1 = {
                jenis_bangunan_resume,
                average_sewa,
                min_sewa,
                max_sewa,
                total_sewa,
                count_sewa,
                manifest_survey_1,
              };
              for (const key in object_survey_1) {
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: key,
                    value_child: object_survey_1[key],
                  },
                });
              }
            } else {
              const res = await axios.get(
                bun_server +
                  `/sini_v2/${item}?lat=${lat}&long=${long}&km_rad=${km_rad}&demography_one_id=${demography_one_id}`,
                config
              );
              const data = res?.data || {};
              if (item === "demography_one") {
                title_1 =
                  data?.demography_one?.properties?.["DESA ATAU KELURAHAN"] ||
                  "";
                title_2 =
                  data?.demography_one?.properties?.["KABUPATEN ATAU KOTA"] ||
                  "";
                if (sini_data_mode === "sini_rdtr") {
                  title_1 = `RDTR ${title_1}`;
                }
                demography_one_id = data?.demography_one?._id;
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: "title_1",
                    value_child: title_1,
                  },
                });
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: "title_2",
                    value_child: title_2,
                  },
                });
                dispatch({
                  type: "state_update",
                });
              }

              if (item === "poi_property") {
                //aggreate nilai properti ke dalam satu variabel dan simpan ke db
                //supaya tidak berkali-kali mengaggregate ketika get data
                //dan data sudah tersimpan
                const result_property = aggregate_property_price(
                  data.poi_property
                );

                for (const property in result_property) {
                  dispatch({
                    type: "edit_inside_array_sini",
                    payload: {
                      key: "ai_parent_detail_list",
                      id_key_parent: "request_id",
                      id_value_parent: request_id,
                      id_key_child: property,
                      value_child: result_property[property],
                    },
                  });
                }
                json_db = { ...json_db, ...result_property };
              }
              //looping object response
              const item_object = [
                "demography_one",
                "people_spending",
                "landvalue_one",
                "landzone_one",
                "flood_one",
                "landslide_one",
                "tsunami_one",
                "rdtr_one",
              ];
              const item_array = [
                "demography_neighbor",
                "landvalue_neighbor",
                "landzone_neighbor",
                "flood_neighbor",
                "landslide_neighbor",
                "tsunami_neighbor",
                "rdtr_neighbor",
                "poi_property",
              ];
              for (const property in data) {
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: property,
                    value_child: data[property],
                  },
                });
                if (item_object.includes(property)) {
                  if (data?.[property]?._id) {
                    json_db[property] = data?.[property]?._id;
                  }
                } else if (item_array.includes(property)) {
                  if (data?.[property]?.length > 0) {
                    const array = data?.[property].map(
                      (element) => element?._id
                    );
                    json_db[property] = array;
                  }
                }
              }
              dispatch({
                type: "state_update",
              });
              if (idx === 0) {
                dispatch(fly_init_update());
              }
              if (idx === 1) {
                dispatch(fly_init_marker());
              }
            }
            dispatch({
              type: "set_value_sini",
              payload: {
                key: "current_loading",
                value: item,
              },
            });
            dispatch({
              type: "clear_loading_action",
              payload: item,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(async () => {
      try {
        const body = {
          request_id: request_id,
          json_db: json_db,
          lat,
          long,
          km_rad,
          title_1,
          title_2,
        };
        await axios.post(bun_server + "/sini_v2/save_sini", body, config);
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "request_id_loading",
            value: "",
          },
        });
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "sini_choose_map_active",
            value: false,
          },
        });
        dispatch({
          type: "clear_loading_action",
          payload: "sini_load_parent",
        });
        dispatch({
          type: "state_update",
        });
        dispatch(get_quota_access());
        dispatch(get_quota_access_sini_ai());
        dispatch(get_quota_ai_chat());
      } catch (error) {}
    });
  } catch (error) {}
};

/**
 * @route : admin_information
 * @endpoint : /sini_v2/admin_information
 * @methode : get
 * @params :
 */
export const admin_information = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "admin_information",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/admin_information`,
      config
    );
    const data = res.data;
    if (data) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "admin_information",
          value: data,
        },
      });
    }
    dispatch({
      type: "clear_loading_action",
      payload: "admin_information",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "admin_information",
    });
  }
};
