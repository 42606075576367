import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import get_url_query from "../../App/validation/get_url_query";
import maplibregl from "maplibre-gl";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const geojson_fill_a = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [107.60430571099687, -6.921100460746715],
            [107.60417057980476, -6.921362514292781],
            [107.60450055132031, -6.921431147340343],
            [107.60452883459311, -6.921169093832361],
            [107.60442512925965, -6.9210224685917865],
            [107.60430571099687, -6.921100460746715],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        tinggi_meter: 100,
      },
    },
  ],
};
const paint_object_fill_a = {
  "fill-color": "blue",
  "fill-opacity": 1,
  "fill-outline-color": "#000",
};
const geojson_fill_b = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        coordinates: [
          [
            [107.60413959441604, -6.920973192156239],
            [107.60468863536681, -6.9210013232647185],
            [107.60464967117122, -6.921558670516674],
            [107.60408291922084, -6.921541088594637],
            [107.60413959441604, -6.920973192156239],
          ],
        ],
        type: "Polygon",
      },
      properties: {},
    },
  ],
};
const paint_object_fill_b = {
  "fill-color": "red",
  "fill-opacity": 1,
  "fill-outline-color": "#000",
};

const geojson_ext = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [107.6041934095702, -6.922076311921259],
            [107.60435657843874, -6.9219721822137075],
            [107.60452557476691, -6.92212259178396],
            [107.60453140222654, -6.92164822297552],
            [107.6044031981155, -6.92182755758175],
            [107.60421089194904, -6.921625083021482],
            [107.6041934095702, -6.922076311921259],
          ],
        ],
      },
      properties: {
        name: "Java Island",
        tinggi_meter: 100,
        building_color: "blue",
        base_meter: 0,
      },
    },
  ],
};
const paint_object_ext = {
  "fill-extrusion-color": "blue",
  "fill-extrusion-height": 100,
  "fill-extrusion-base": 0,
  "fill-extrusion-opacity": 0.5,
};
class LIBRE_NETA extends Component {
  state = {};

  componentDidMount() {
    const mode = get_url_query("mode");
    if (mode === "neta") {
      this.on_fly();
      this.on_render_content();
    }
    // this.on_fly();
  }

  componentDidUpdate(prevProps) {
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      const mode = get_url_query("mode");
      if (mode === "neta") {
        this.on_fly();
        this.on_render_content();
      }
      // this.on_fly();
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map = "libre_neta";
    const id_map_2 = "libre_neta_fill_a";
    const id_map_3 = "libre_neta_fill_b";
    if (!map_object.getSource(id_map)) {
      map_object.addSource(id_map, {
        type: "geojson",
        data: geojson_ext,
      });
    } else {
      map_object.getSource(id_map).setData(geojson_ext);
    }

    if (!map_object.getLayer(id_map)) {
      map_object.addLayer({
        id: id_map,
        source: id_map,
        type: "fill-extrusion",
        paint: paint_object_ext,
        layout: { visibility: "visible" },
      });
    } else {
      for (const key in paint_object_ext) {
        map_object.setPaintProperty(id_map, key, paint_object_ext[key]);
      }

      // map_object.setLayoutProperty(id_map, "visibility", "none");
    }

    //fill a
    if (!map_object.getSource(id_map_2)) {
      map_object.addSource(id_map_2, {
        type: "geojson",
        data: geojson_fill_a,
      });
    } else {
      map_object.getSource(id_map_2).setData(geojson_fill_a);
    }

    if (!map_object.getLayer(id_map_2)) {
      map_object.addLayer({
        id: id_map_2,
        source: id_map_2,
        type: "fill",
        paint: paint_object_fill_a,
        layout: { visibility: "visible" },
      });
    } else {
      for (const key in paint_object_fill_a) {
        map_object.setPaintProperty(id_map_2, key, paint_object_fill_a[key]);
      }

      // map_object.setLayoutProperty(id_map, "visibility", "none");
    }

    // fill b
    if (!map_object.getSource(id_map_3)) {
      map_object.addSource(id_map_3, {
        type: "geojson",
        data: geojson_fill_b,
      });
    } else {
      map_object.getSource(id_map_3).setData(geojson_fill_b);
    }

    if (!map_object.getLayer(id_map_3)) {
      map_object.addLayer(
        {
          id: id_map_3,
          source: id_map_3,
          type: "fill",
          paint: paint_object_fill_b,
          layout: { visibility: "visible" },
        },
        id_map_2
      );
    } else {
      for (const key in paint_object_fill_b) {
        map_object.setPaintProperty(id_map_3, key, paint_object_fill_b[key]);
      }

      // map_object.setLayoutProperty(id_map, "visibility", "none");
    }

    this.addGLTFLayer(map_object);
  };
  addGLTFLayer = (map_object) => {
    const id_3d_model = "3d-model-layer";

    // Check if the 3D model layer already exists
    if (!map_object.getLayer(id_3d_model)) {
      const modelOrigin = [107.60575671632506, -6.919971480771395];
      const modelAltitude = 0;
      const modelRotate = [Math.PI / 2, 0, 0];

      const modelAsMercatorCoordinate =
        maplibregl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

      // transformation parameters to position, rotate and scale the 3D model onto the map
      const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };

      // configuration of the custom layer for a 3D model per the CustomLayerInterface
      const customLayer = {
        id: "3d-model",
        type: "custom",
        renderingMode: "3d",
        onAdd(map, gl) {
          this.camera = new THREE.Camera();
          this.scene = new THREE.Scene();

          // create two three.js lights to illuminate the model
          const directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -70, 100).normalize();
          this.scene.add(directionalLight);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff);
          directionalLight2.position.set(0, 70, 100).normalize();
          this.scene.add(directionalLight2);

          // use the three.js GLTF loader to add the 3D model to the three.js scene
          const loader = new GLTFLoader();
          loader.load(
            "https://maplibre.org/maplibre-gl-js/docs/assets/34M_17/34M_17.gltf",
            (gltf) => {
              this.scene.add(gltf.scene);
            }
          );
          this.map = map;

          // use the MapLibre GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          });

          this.renderer.autoClear = false;
        },
        render(gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );

          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        },
      };

      map_object.addLayer(customLayer);
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_ext);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_NETA);
