import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import get_url_query from "../../App/validation/get_url_query";

const geojson_fill_extrusion = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        color: "orange",
        height: 100,
        opacity: 0.5,
      },
      geometry: {
        coordinates: [
          [
            [110.36176569429517, -7.771668328689174],
            [110.36170364219754, -7.772472865615484],
            [110.36224083607135, -7.772516781342162],
            [110.36228870483194, -7.771708731234767],
            [110.36176569429517, -7.771668328689174],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};

const geojson_fill = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        color: "green",
        height: 50,
        opacity: 0.2,
      },
      geometry: {
        coordinates: [
          [
            [110.36235607382815, -7.771735081334825],
            [110.36231352381907, -7.772458813197147],
            [110.36247663218944, -7.772474622859974],
            [110.36246422177038, -7.772606370025713],
            [110.36285780936043, -7.7726256929406645],
            [110.36288263020049, -7.7720723545912875],
            [110.36308474274625, -7.772093434161377],
            [110.36309715316531, -7.771924797574982],
            [110.36295177396568, -7.771908987891436],
            [110.36296595730266, -7.771780753771537],
            [110.36265569681467, -7.771770213978314],
            [110.36235607382815, -7.771735081334825],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};

const geojson_fill_selatan_sma = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [
            [110.36129607940399, -7.772363867552457],
            [110.36133282619005, -7.772385106251733],
            [110.36132057726081, -7.772740095793125],
            [110.36148900003343, -7.772743129890159],
            [110.36180440995355, -7.772743129890159],
            [110.36191771254698, -7.772730993500275],
            [110.3622668070214, -7.772724925306079],
            [110.3626005903347, -7.7727370616952385],
            [110.36316404106503, -7.772806845929708],
            [110.36311504534802, -7.77247612924026],
            [110.36129607940399, -7.772363867552457],
          ],
        ],
        type: "Polygon",
      },
    },
  ],
};

class LIBRE_FATHUR extends Component {
  state = {};

  componentDidMount() {
    this.on_render_content();
    const mode = get_url_query("mode");
    if (mode === "fathur") {
      this.on_fly();
      this.on_render_content();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      this.on_render_content();
      const mode = get_url_query("mode");
      if (mode === "fathur") {
        this.on_fly();
        this.on_render_content();
      }
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map_1 = "lapangan_karangwaru";
    if (!map_object.getSource(id_map_1)) {
      map_object.addSource(id_map_1, {
        type: "geojson",
        data: geojson_fill_extrusion,
      });
    } else {
      map_object.getSource(id_map_1).setData(geojson_fill_extrusion);
    }

    const fill_extrusion_paint = {
      "fill-extrusion-color": ["get", "color"],
      "fill-extrusion-height": ["get", "height"],
    };
    if (!map_object.getLayer(id_map_1)) {
      map_object.addLayer({
        id: id_map_1,
        source: id_map_1,
        type: "fill-extrusion",
        paint: fill_extrusion_paint,
        layout: { visibility: "visible" },
      });
    } else {
      for (const item in fill_extrusion_paint) {
        map_object.setPaintProperty(id_map_1, item, fill_extrusion_paint[item]);
      }
    }

    //===========================

    const id_map_2 = "SMAN 4 Yogyakarta";
    if (!map_object.getSource(id_map_2)) {
      map_object.addSource(id_map_2, {
        type: "geojson",
        data: geojson_fill,
      });
    } else {
      map_object.getSource(id_map_2).setData(geojson_fill);
    }

    const fill_paint = {
      "fill-color": ["get", "color"],
      "fill-opacity": ["get", "opacity"],
      "fill-outline-color": "#000",
    };

    if (!map_object.getLayer(id_map_2)) {
      map_object.addLayer({
        id: id_map_2,
        source: id_map_2,
        type: "fill",
        paint: fill_paint,
        layout: { visibility: "visible" },
      });
    } else {
      for (const item in fill_paint) {
        map_object.setPaintProperty(id_map_2, item, fill_paint[item]);
      }
    }

    //===========================

    const id_map_selatan_sma = "id_map_selatan_sma";
    if (!map_object.getSource(id_map_selatan_sma)) {
      map_object.addSource(id_map_selatan_sma, {
        type: "geojson",
        data: geojson_fill_selatan_sma,
      });
    } else {
      map_object
        .getSource(id_map_selatan_sma)
        .setData(geojson_fill_selatan_sma);
    }

    const fill_paint_selatan_sma = {
      "fill-color": "blue",
      // "fill-opacity": ["get", "opacity"],
      "fill-outline-color": "#000",
    };

    if (!map_object.getLayer(id_map_selatan_sma)) {
      map_object.addLayer(
        {
          id: id_map_selatan_sma,
          source: id_map_selatan_sma,
          type: "fill",
          paint: fill_paint_selatan_sma,
          layout: { visibility: "visible" },
        },
        "building"
      );
    } else {
      for (const item in fill_paint_selatan_sma) {
        map_object.setPaintProperty(
          id_map_selatan_sma,
          item,
          fill_paint_selatan_sma[item]
        );
      }
    }

    // console.log(map_object.getStyle());
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] = bbox(
        geojson_fill_extrusion
      );
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_FATHUR);
